import Api from './ApiService'

export default {
    get(keyword, pageNumber, sort) {
        return Api().get(`tag?keyword=${keyword}&pageNumber=${pageNumber}&sort=${sort}`);
    },
    getOne(id) {
        return Api().get(`tag/${id}`);
    },
    create(data) {
        return Api().post(`tag`, data);
    },
    update(data) {
        return Api().put(`tag/${data.id}`, data);
    },
    delete(id) {
        return Api().delete(`tag/${id}`);
    }
}