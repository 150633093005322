import FreteService from '../../services/FreteService'

const state = () => ({
    shipping: null,
    loadingShipping: false,
    showModalEtiqueta: false,
    seguro: 100,
    stageShipping: 1
})
  
const mutations = {
    SET_SHIPPING: (state, data) => {
        state.shipping = data
    },
    SET_LOADING_SHIPPING: (state, boolean) => {
        state.loadingShipping = boolean
    },
    SHOW_MODAL_ETIQUETA: (state, boolean) => {
        state.showModalEtiqueta = boolean
    },
    SET_STAGE_SHIPPING: (state, index) => {
        if(state.stageShipping == index) return state.stageShipping = null
        state.stageShipping = index
    },
}

const actions = {
    async getInfoShipping({ state, commit }) {
        try {
            commit('SET_LOADING_SHIPPING', true)
            const responseME = await FreteService.getInfoShipping(state.shipping.order_id);
            if(state.shipping.tracking != responseME.data.tracking){
                state.shipping.tracking = responseME.data.tracking;
                await FreteService.update(state.shipping);
            }
            commit('SET_LOADING_SHIPPING', false)
        } catch (error) {
            commit('SET_LOADING_SHIPPING', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async copyShippingCart({ commit, state, rootState }, data) {
        try {
            commit('SET_LOADING_SHIPPING', true)
            data = [
                {
                    "service_id": rootState.order.order.ShippingMethodId,
                    "service": rootState.order.order.shippingMethod,
                    "to": {
                        "name": rootState.order.order.User.firstName + ' '+ rootState.order.order.User.lastName,
                        "phone": rootState.order.order.User.phone,
                        "email": rootState.order.order.User.email,
                        "document": rootState.order.order.User.cpf,
                        "company_document": "",
                        "state_register":  rootState.order.order.deliveryAddress.state,                      
                        "address": rootState.order.order.deliveryAddress.street,
                        "complement": rootState.order.order.deliveryAddress.complement,
                        "number": rootState.order.order.deliveryAddress.number,
                        "district": rootState.order.order.deliveryAddress.neighborhood,
                        "city": rootState.order.order.deliveryAddress.city,
                        "country_id": "BR",
                        "postal_code": rootState.order.order.deliveryAddress.zip,
                        "state_abbr": rootState.order.order.deliveryAddress.state,
                        "note": ""
                    },
                    "products": [
                        {
                            "name": `Order #${rootState.order.order.id}`,
                            "quantity": rootState.order.order.OrderItems.length,
                            "unitary_value": rootState.order.order.totalAmount
                        }
                    ],
                    "options": {
                        "insurance_value": state.seguro ? (rootState.order.order.totalAmount * state.seguro / 100).toFixed(2) : 0,
                        "receipt": false,
                        "own_hand": false,
                        "reverse": false,
                        "non_commercial": false,
                        "invoice": {
                            "key": rootState.order.order.notaFiscal
                        },
                        "tags": [
                            {
                            "tag": rootState.order.order.id,
                            "Url": `${process.env.VUE_APP_URL}/track_order/#${rootState.order.order.id}`
                            }
                        ]
                    }
                }
            ];
            const volumes = [];
            rootState.order.order.OrderItems.forEach(item => {
                const volume = {
                    height: item.Product.height,
                    width: item.Product.width,
                    length: item.Product.length,
                    weight: item.Product.weight
                };
                if (!volume.height || !volume.width || !volume.length || !volume.weight) {
                    commit('SET_ALERT', {
                        heading: 'error',
                        message: `O produto ID ${item.Product.id} está sem as dimensões. Por favor, preencha as dimensões.`
                    });
                    return;
                }
                volumes.push(volume);
            });
            data[0].volumes = volumes;

            commit('SET_LOADING_SHIPPING', false)
            return data;
        } catch (error) {
            commit('SET_LOADING_SHIPPING', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async addShippingCart({ commit, state, rootState }, data) {
        try {
            commit('SET_LOADING_SHIPPING', true)
        
            data = [
                {
                    "service": rootState.order.order.ShippingMethodId,
                    "to": {
                        "name": rootState.order.order.User.firstName + ' '+ rootState.order.order.User.lastName,
                        "phone": rootState.order.order.User.phone,
                        "email": rootState.order.order.User.email,
                        "document": rootState.order.order.User.cpf,
                        "company_document": "",
                        "state_register":  rootState.order.order.deliveryAddress.state,                      
                        "address": rootState.order.order.deliveryAddress.street,
                        "complement": rootState.order.order.deliveryAddress.complement,
                        "number": rootState.order.order.deliveryAddress.number,
                        "district": rootState.order.order.deliveryAddress.neighborhood,
                        "city": rootState.order.order.deliveryAddress.city,
                        "country_id": "BR",
                        "postal_code": rootState.order.order.deliveryAddress.zip,
                        "state_abbr": rootState.order.order.deliveryAddress.state,
                        "note": ""
                    },
                    "products": [
                        {
                            "name": `Order #${rootState.order.order.id}`,
                            "quantity": rootState.order.order.OrderItems.length,
                            "unitary_value": rootState.order.order.totalAmount
                        }
                    ],
                    "options": {
                        "insurance_value": state.seguro ? (rootState.order.order.totalAmount * state.seguro / 100).toFixed(2) : 0,
                        "receipt": false,
                        "own_hand": false,
                        "reverse": false,
                        "non_commercial": false,
                        "invoice": {
                            "key": rootState.order.order.notaFiscal
                        },
                        "tags": [
                            {
                            "tag": rootState.order.order.id,
                            "Url": `${process.env.VUE_APP_URL}/track_order/#${rootState.order.order.id}`
                            }
                        ]
                    }
                }
            ];
            const volumes = [];
            rootState.order.order.OrderItems.forEach(item => {
                const volume = {
                    height: item.Product.height,
                    width: item.Product.width,
                    length: item.Product.length,
                    weight: item.Product.weight
                };
                if (!volume.height || !volume.width || !volume.length || !volume.weight) {
                    commit('SET_ALERT', {
                        heading: 'error',
                        message: `O produto ID ${item.Product.id} está sem as dimensões. Por favor, preencha as dimensões.`
                    });
                    return;
                }
                volumes.push(volume);
            });
            data[0].volumes = volumes;

            const responseME = await FreteService.addShippingCart(data);
            const shipping = responseME.data;
            state.shipping = {
                order_id: shipping.id,
                order_protocol: shipping.protocol,
                service_id: shipping.service_id,
                price: shipping.price,
                delivery_min: shipping.delivery_min,
                delivery_max: shipping.delivery_max,
                OrderId: rootState.order.order.id
            }
            commit('SET_SHIPPING', state.shipping)
            commit('SET_STAGE_SHIPPING', 2)
            commit('SET_LOADING_SHIPPING', false)
        } catch (error) {
            commit('SET_LOADING_SHIPPING', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async payShippingCart({ commit, state, rootState }, idShipping) {
        try {
            commit('SET_LOADING_SHIPPING', true)
            const responseME = await FreteService.payShippingCart({ orders: [ idShipping ] });
            const transactions = responseME.data.purchase.transactions[0];
            const orders = responseME.data.purchase.orders[0];

            state.shipping.transaction_id = transactions.id;
            state.shipping.transaction_protocol = transactions.protocol;
            state.shipping.status = transactions.status;

            if(state.shipping.status == "authorized") {
                try {
                    const response = await FreteService.create({transactions, orders, OrderId: rootState.order.order.id});
                    commit('SET_SHIPPING', response.data.item)
                } catch(err){
                    commit('SET_ALERT', {
                        heading: 'error',
                        message: err.response.data.message
                    });
                }
            }
            commit('SET_SHIPPING', state.shipping)
            commit('SET_STAGE_SHIPPING', 3)
            commit('SET_LOADING_SHIPPING', false)
        } catch (error) {
            commit('SET_LOADING_SHIPPING', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async generateEtiqueta({ commit, dispatch }, idShipping) {
        try {
            commit('SET_LOADING_SHIPPING', true)
            await FreteService.generateEtiqueta({ orders: [ idShipping ] });
            await dispatch('printEtiqueta', idShipping);
            commit('SET_LOADING_SHIPPING', false)
        } catch (error) {
            commit('SET_LOADING_SHIPPING', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response[idShipping].message || "Falha ao gerar Etiqueta"
            });
        }
    },
    async printEtiqueta({ commit, state, dispatch }, idShipping) {
        try {
            commit('SET_LOADING_SHIPPING', true)
            const responseME = await FreteService.printEtiqueta({ mode: "public", orders: [ idShipping ] });
            state.shipping.print = responseME.data.url;
            await FreteService.update(state.shipping);  
            await dispatch('getInfoShipping');
            commit('SET_SHIPPING', state.shipping)
            commit('SET_STAGE_SHIPPING', 4)
            commit('SET_LOADING_SHIPPING', false)
        } catch (error) {
            commit('SET_LOADING_SHIPPING', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async setStageShipping({ state, commit }, etapa) {
        try {
            commit('SET_LOADING_SHIPPING', true)
            if(etapa > 1){
                if(!state.shipping){
                    commit('SET_LOADING_SHIPPING', false);
                    return commit('SET_ALERT', {
                        heading: 'error',
                        message: "Gere uma cotação do frete"
                    });
                }
                commit('SET_STAGE_SHIPPING', etapa)
            }
            if(etapa === 1){
                commit('SET_STAGE_SHIPPING', 1)
            }
            commit('SET_LOADING_SHIPPING', false)
        } catch (error) {
            commit('SET_LOADING_SHIPPING', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getShipping: state => state.shipping,
    getLoadingShipping: state => state.loadingShipping,
    getShowModalEtiqueta: state => state.showModalEtiqueta,
    getStageShipping: state => state.stageShipping,
}

export default {
    state,
    getters,
    mutations,
    actions
};