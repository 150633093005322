import CharacteristicVariationsService from '../../services/CharacteristicVariationsService'

const state = () => ({
    loadingCharacteristics: false,
    characteristics: [],
    characteristicAtual: null, //Variavel que esta atualmente no produto
    selectedCharacteristic: null, //Variavel selecionada 
    variations: null,
    show_modal_new_characteristic: false
})
  
const mutations = {
    SET_CHARACTERISTICS: (state, payload) => {
        state.characteristics = payload
    },
    SET_CHARACTERISTIC_ATUAL: (state, payload) => {
        if(payload == null) state.characteristicAtual = null
        state.characteristicAtual = Object.assign({}, payload); 
    },
    SET_CHARACTERISTIC_SELECTED: (state, payload) => {
        if(payload == null) state.characteristicAtual = null
        state.selectedCharacteristic = Object.assign({}, payload); 
    },
    SET_VARIATIONS: (state, payload) => {
        state.variations = payload
    },
    SET_LOADING_CHARACTERISTICS_VARIATIONS: (state, payload) => {
        state.loadingCharacteristics = payload
    },
    SHOW_MODAL_NEW_CHARACTERISTIC: (state, boolean) => {
        state.show_modal_new_characteristic = boolean
    }
}

const actions = {
    async fetchProductCharVariation({commit}, idProduct){
        try{
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', true)
            const response = await CharacteristicVariationsService.getProductCharVariation(idProduct);
            commit('UPDATE_VAR_PRODUCT',  response.data.ProductCharVariations)
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', false)
        } catch (error) {
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', false);
            // commit('LOG_USER_OUT'); //Deu errado desloga
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchCharacteristic({commit}, { keyword = '', pageNumber = 1, sortBy = 'mais recentes' }){
        try{
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', true)
            const response = await CharacteristicVariationsService.getCharacteristic(keyword, pageNumber, sortBy);
            commit('SET_CHARACTERISTICS', response.data)
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', false)
        } catch (error) {
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', false);
            // commit('LOG_USER_OUT'); //Deu errado desloga
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchVariation({commit}, idCharacteristic){
        try{
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', true)
            const response = await CharacteristicVariationsService.getVariation(idCharacteristic);
            commit('SET_VARIATIONS', response.data)
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', false)
        } catch (error) {
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', false);
            // commit('LOG_USER_OUT'); //Deu errado desloga
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createdProcuctCharacteristicVariation({commit, dispatch}, {idProduct, Characteristic, Variation}){
        try{
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', true)
            const response_variation = await CharacteristicVariationsService.setVariation(Characteristic, Variation);
            await CharacteristicVariationsService.setProcuctCharacteristicVariation(idProduct, response_variation.data, Variation.quantity, Characteristic);
            dispatch('fetchVariation', Characteristic.id)
            dispatch('fetchProductCharVariation', idProduct)
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', false)
        } catch (error) {
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async addVariationProduct({commit, dispatch}, {idProduct, Variation, getCharacteristicSelected}){
        try{
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', true)
            const response = await CharacteristicVariationsService.setProcuctCharacteristicVariation(idProduct, Variation, Variation.quantity, getCharacteristicSelected);
            dispatch('fetchProductCharVariation', response.data.productId)
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', false)
        } catch (error) {
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createCharacteristic({commit, dispatch}, Characteristic){
        try{
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', true)
            const response = await CharacteristicVariationsService.createCharacteristic(Characteristic);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            dispatch('fetchCharacteristic', { keyword: ''})
            commit('SET_CHARACTERISTIC_SELECTED', response.data.characteristic)
            commit('SHOW_MODAL_NEW_CHARACTERISTIC', false)
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', false)
        } catch (error) {
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updateCharacteristic({commit}, { idProduct, Characteristic }){
        try{
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', true)
            await CharacteristicVariationsService.updateCharacteristic(idProduct, Characteristic);
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', false)
        } catch (error) {
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updateQntVariation({commit}, { idProduct, Variation, newQuantity }){
        try{
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', true)
            await CharacteristicVariationsService.updateQntProcuctCharacteristicVariation(idProduct, Variation, newQuantity);
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', false)
        } catch (error) {
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async deleteVariationProduct({commit, dispatch}, {idProduct, idVariation}){
        try{
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', true)
            await CharacteristicVariationsService.deleteProcuctCharacteristicVariation(idProduct, idVariation);
            dispatch('fetchProductCharVariation', idProduct)
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', false)
        } catch (error) {
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async deleteVariation({commit, dispatch}, {Variation}){
        try{
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', true)
            await CharacteristicVariationsService.deleteVariation(Variation.id);
            dispatch('fetchVariation', Variation.CharacteristicId)
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', false)
        } catch (error) {
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchCharracteristicAtiva({commit, rootState}){
        try{
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', true)
            if (rootState.product.product && rootState.product.product.ProductCharVariations) {
                const characteristic = rootState.product.product.ProductCharVariations.find((variation) => variation.status === 'ativo');
                if(characteristic){
                    commit('SET_CHARACTERISTIC_ATUAL', characteristic.Characteristic)
                    commit('SET_CHARACTERISTIC_SELECTED', characteristic.Characteristic)
                }else {
                    commit('SET_CHARACTERISTIC_ATUAL', null)
                    commit('SET_CHARACTERISTIC_SELECTED', null)
                }
            }
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', false)
        } catch (error) {
            commit('SET_LOADING_CHARACTERISTICS_VARIATIONS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    }
}

const getters = {
    getCharacteristics: state => state.characteristics,
    getCharacteristicAtual: state => state.characteristicAtual,
    getCharacteristicSelected: state => state.selectedCharacteristic,
    getVariations: state => state.variations,
    getLoadingCharacteristicsVariations: state => state.loadingCharacteristics,
    getShowModalNewCharacteristic: state => state.show_modal_new_characteristic
}

export default {
    state,
    getters,
    mutations,
    actions
};