import Api from './ApiService';

export default {
    create(transactions, orders, OrderId){
        return Api().post(`/orderShipping`, transactions, orders, OrderId);
    },
    update(data){
        return Api().put(`/orderShipping/${data.id}`, data);
    },
    getInfoShipping(idShipping){
        return Api().get(`/melhorenvio/cart/${idShipping}`);
    },
    addShippingCart(data){
        return Api().post(`/melhorenvio/cart`, data);
    },
    payShippingCart(data){
        return Api().post(`/melhorenvio/shipment/checkout`, data);
    },
    generateEtiqueta(orders){
        return Api().post(`/melhorenvio/shipment/generate`, orders);
    },
    printEtiqueta(orders){
        return Api().post(`/melhorenvio/shipment/print`, orders);
    },
}