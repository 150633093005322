import CategoryService from '../../services/CategoryService'
import router from '../../router'

const state = () => ({
    loadingCategory: false,
    category: null,
    categories: [],
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_category: false
})
  
const mutations = {
    SET_CATEGORY: (state, payload) => {
        state.category = payload
    },
    SET_CATEGORIES: (state, payload) => {
        state.categories = payload.categories
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total

    },
    SET_MORE_CATEGORIES(state, payload) {
        state.categories = [...state.categories, ...payload.categories]
        state.page =  payload.pageNumber
        state.pages =  payload.pages
    },
    SET_LOADING_CATEGORIES: (state, payload) => {
        state.loadingCategory = payload
    },
    SET_SHOW_MODAL_NEW_CATEGORY: (state, payload) => {
        state.show_modal_new_category = payload
    },
}

const actions = {
    async fetchCategories({commit}, { keyword = '', pageNumber = 1, sortBy = 'mais recentes' }){
        try{
            commit('SET_LOADING_CATEGORIES', true)
            const response = await CategoryService.get(keyword, pageNumber, sortBy);
            commit('SET_CATEGORIES', response.data)
            commit('SET_LOADING_CATEGORIES', false)
        } catch (error) {
            commit('SET_LOADING_CATEGORIES', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchMoreCategories({commit}, { keyword = '', pageNumber, sortBy = 'mais recentes'} ){
        try{
            commit('SET_LOADING_CATEGORIES', true)
            const response = await CategoryService.get(keyword, pageNumber, sortBy);
            commit('SET_MORE_CATEGORIES', response.data)
            commit('SET_LOADING_CATEGORIES', false)
        } catch (error) {
            commit('SET_LOADING_CATEGORIES', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchCategory({commit}, id){
        try{
            commit('SET_LOADING_CATEGORIES', true)
            const response = await CategoryService.getOne(id);
            commit('SET_CATEGORY', response.data)
            commit('SET_LOADING_CATEGORIES', false)
        } catch (error) {
            commit('SET_LOADING_CATEGORIES', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createCategory({commit, dispatch}, data){
        try{
            commit('SET_LOADING_CATEGORIES', true)
            const response = await CategoryService.create(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            dispatch('fetchCategories', { keyword: ''})
            commit('SET_SHOW_MODAL_NEW_CATEGORY', false)
            commit('SET_LOADING_CATEGORIES', false)
        } catch (error) {
            commit('SET_LOADING_CATEGORIES', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadeCategory({commit}, data){
        try{
            commit('SET_LOADING_CATEGORIES', true)
            const response = await CategoryService.update(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_CATEGORIES', false)
        } catch (error) {
            commit('SET_LOADING_CATEGORIES', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async deleteCategory({state, commit}, id) {
        try{
            commit('SET_LOADING_CATEGORIES', true);
            if(state.category.id == id){
                const response = await CategoryService.delete(id);
                router.push({path: `/categories`});
                commit('SET_ALERT', {
                    heading: 'success',
                    message: response.data.message
                });
            }
            commit('SET_SHOW_MODAL_CONFIRMATION', false);
            commit('SET_LOADING_CATEGORIES', false);
        } catch (error) {
            commit('SET_LOADING_CATEGORIES', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    }
}

const getters = {
    getCategory: state => state.category,
    getCategories: state => state.categories,
    getLoadingCategory: state => state.loadingCategory,
    getShowModalNewCategory: state => state.show_modal_new_category
}

export default {
    state,
    getters,
    mutations,
    actions
};