import Api from './ApiService'

export default {
    getProductCharVariation(idProduct) {
        return Api().get(`characteristicVariations/all_relation/${idProduct}`);
    },
    getCharacteristic(keyword, pageNumber, sort) {
        return Api().get(`characteristicVariations?keyword=${keyword}&pageNumber=${pageNumber}&sort=${sort}`);
    },
    getVariation(idCharacteristic) {
        return Api().get(`characteristicVariations/variations/${idCharacteristic}`);
    },
    setVariation(Characteristic, Variation) {
        return Api().post(`characteristicVariations/variations/add`, {Characteristic, Variation});
    },
    setProcuctCharacteristicVariation(idProduct, Variation, Quantity, CharacteristicAtual) {
        return Api().post(`characteristicVariations/product_characteristic_variation/add`, {idProduct, Variation, Quantity, CharacteristicAtual});
    },
    createCharacteristic(Characteristic) {
        return Api().post(`characteristicVariations/characteristic`, Characteristic);
    },
    updateCharacteristic(productId, Characteristic) {
        return Api().patch(`characteristicVariations/product_characteristic_variation/`, {productId, Characteristic});
    },
    updateQntProcuctCharacteristicVariation(productId, variationId, newQuantity) {
        return Api().patch(`characteristicVariations/product_characteristic_variation/updateQntProduct`, {productId, variationId, newQuantity});
    },
    deleteProcuctCharacteristicVariation(idProduct, idVariation) {
        return Api().delete(`characteristicVariations/product_characteristic_variation/delete/${idProduct}/${idVariation}`);
    },
    deleteVariation(idVariation) {
        return Api().delete(`characteristicVariations/variation/delete/${idVariation}`);
    }
}