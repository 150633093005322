import Api from './ApiService'

export default {
    get(keyword, pageNumber, sort) {
        return Api().get(`category?keyword=${keyword}&pageNumber=${pageNumber}&sort=${sort}`);
    },
    getOne(id) {
        return Api().get(`category/${id}`);
    },
    create(data) {
        return Api().post(`category`, data);
    },
    update(data) {
        return Api().put(`category/${data.id}`, data);
    },
    delete(id) {
        return Api().delete(`category/${id}`);
    },
}