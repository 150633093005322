import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/HomeView.vue'),
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('@/views/LoginView.vue')
  },
  {
    path: '/products',
    name: 'Produtos',
    component: () => import('@/views/products/List_Products.vue'),
  },
  {
    path: '/form_product',
    children: [
      { 
        path: '', 
        name: 'Form Produto', 
        component: () => import('@/views/products/FormProduct.vue'),
      },
      { 
        path: ':id_product', 
        name: 'Editar Produto',
        component: () => import('@/views/products/EditProduct.vue'),
      }
    ],
  },
  {
    path: '/categories',
    name: 'Categorias',
    component: () => import('@/views/categories/List_Categories.vue'),
  },
  {
    path: '/form_category/',
    name: 'Form Categoria',
    component: () => import('@/views/categories/FormCategory.vue'),
    children: [{
      path: ':id_category', 
      name: 'Editar Categoria', 
    component: () => import('@/views/categories/FormCategory.vue')
    }],
  },
  {
    path: '/orders',
    name: 'Ordens',
    component: () => import('@/views/orders/List_Orders.vue'),
  },
  {
    path: '/form_order/',
    name: 'Form Order',
    component: () => import('@/views/orders/FormOrder.vue'),
    children: [{
      path: ':id_order', 
      name: 'Editar Order', 
      component: () => import('@/views/orders/FormOrder.vue')
    }],
  },
  {
    path: '/payments',
    name: 'Pagamentos',
    component: () => import('@/views/payments/List_Payments.vue'),
  },
  {
    path: '/form_payment/',
    name: 'Form Pagamento',
    component: () => import('@/views/payments/FormPayment.vue'),
    children: [{
      path: ':id_payment', 
      name: 'Editar Pagamento', 
      component: () => import('@/views/payments/FormPayment.vue')
    }],
  },
  {
    path: '/users',
    name: 'Usuários',
    component: () => import('@/views/users/List_Users.vue'),
  },
  {
    path: '/form_user/',
    name: 'Form Usuário',
    component: () => import('@/views/users/FormUser.vue'),
    children: [{
      path: ':id_user', 
      name: 'Editar Usuário', 
      component: () => import('@/views/users/FormUser.vue')
    }],
  },
  {
    path: '/tags',
    name: 'Tags',
    component: () => import('@/views/tags/List_Tags.vue'),
  },
  {
    path: '/form_tag/',
    name: 'Form Tag',
    component: () => import('@/views/tags/FormTag.vue'),
    children: [{
      path: ':id_tag', 
      name: 'Editar Tag', 
    component: () => import('@/views/tags/FormTag.vue')
    }],
  },
  {
    path: '/attributes',
    name: 'Attributes',
    component: () => import('@/views/attributes/List_Attributes.vue'),
  },
  {
    path: '/form_attribute/',
    name: 'Form Atributo',
    component: () => import('@/views/attributes/FormAttribute.vue'),
    children: [{
      path: ':id_attribute', 
      name: 'Editar Atributo', 
    component: () => import('@/views/attributes/FormAttribute.vue')
    }],
  },
  {
    path: '/relations',
    name: 'Relations',
    component: () => import('@/views/relations/List_Relations.vue'),
  },
  {
    path: '/form_relation',
    name: 'Relações',
    children: [
      { 
        path: '', 
        name: 'Formulário Relação', 
        component: () => import('@/views/relations/FormRelations.vue'),
      },
      { 
        path: ':id_relation', 
        name: 'Editar Relação',
        component: () => import('@/views/relations/FormRelations.vue'),
      }
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Error 404',
    component: () => import('@/views/Error404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `${to.name} | ${process.env.VUE_APP_TITLE}`
  window.scrollTo(0, 0);
  next()
})

export default router
