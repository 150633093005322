import AuthService from '../../services/AuthService'
import UserService from '../../services/UserService'
import router from '../../router'

const state = () => ({
    isAuthenticated: false,
    loadingUser: false,
    redirect: '',
    token: localStorage.getItem('token') || null,
    user: (() => {
        try {
            return JSON.parse(localStorage.getItem('user')) || null;
        } catch (error) {
            localStorage.removeItem('user');
            return { firstName: ''};
        }
    })(),
    client_user: null,
    client_users: [],
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_user: false,
    new_client_user: {
        firstName: "",
        lastName: "",
        email: "",
        cpf: null,
        phone: null
    }
})
  
const mutations = {
    REDIRECT: (state, payload) => {
        state.redirect = payload;
    },
    SET_AUTH: (state, payload) => {
        localStorage.setItem('token', payload.token)
        localStorage.setItem('user', JSON.stringify(payload.user));
        state.isAuthenticated = true;
        state.user = payload.user
        state.token = payload.token
        if(router.currentRoute.value.path === '/auth' && !state.redirect){
            router.push({path: `/`});
        }
        state.redirect = null;
    },
    SET_USER: (state, payload) => {
        state.client_user =  payload.user
    },
    SET_USERS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.client_users = payload.itens
        }else{
            state.client_users = [...state.client_users, ...payload.itens]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total
    },
    AUTH_USER: (state, payload) => {
        localStorage.setItem('token', payload.token)
        localStorage.setItem('user', JSON.stringify(payload.user));
        state.isAuthenticated = true;
        state.token = payload.token;
        state.user = payload.user;
    },
    SET_LOADING_USER: (state, payload) => {
        state.loadingUser = payload
    },
    LOG_USER_OUT(state){
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('notifications');
        localStorage.removeItem('projects');

        state.token = null;
        state.isAuthenticated = false;
        state.user = null;
        state.projects = null;

        if(router.currentRoute.value.path !== '/auth'){
            router.push({path: `/auth`});
        }
    },
    SET_SHOW_MODAL_NEW_USER: (state, payload) => {
        state.show_modal_new_user = payload
    },
}

const actions = {
    async loginADM({commit}, credentials ){
        try {
            commit('SET_LOADING_USER', true);
            const response = await AuthService.loginADM(credentials);
            commit('SET_AUTH', response.data);
            commit('SET_LOADING_USER', false);
        } catch (error) {
            commit('SET_LOADING_USER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'erro',
                message: error.response.data.message
            });
        }
    },
    async fetchUser({commit}, id){
        try{
            commit('SET_LOADING_USER', true)
            const response = await UserService.getOne(id);
            commit('SET_USER', response.data)
            commit('SET_LOADING_USER', false)
        } catch (error) {
            commit('SET_LOADING_USER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchUsers({commit}, { keyword = '', pageNumber = 1, sortBy = 'mais recentes' }){
        try{
            commit('SET_LOADING_USER', true)
            const response = await UserService.get(keyword, pageNumber, sortBy);
            commit('SET_USERS', response.data)
            commit('SET_LOADING_USER', false)
        } catch (error) {
            commit('SET_LOADING_USER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createUser({commit, state, rootState}, data){
        try{
            commit('SET_LOADING_USER', true)
            const response = await UserService.create(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            rootState.order.new_order.User = response.data.user
            commit('SET_SHOW_MODAL_NEW_USER', false)
            state.new_client_user = { firstName: "", lastName: "", email: "", cpf: null, phone: null };
            commit('SET_LOADING_USER', false)
        } catch (error) {
            commit('SET_LOADING_USER', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadeUser({commit}, data){
        try{
            commit('SET_LOADING_USER', true)
            const response = await UserService.updateUser(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_USER', false)
        } catch (error) {
            commit('SET_LOADING_USER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getUser: state => state.user,
    getClientUser: state => state.client_user,
    getNewClientUser: state => state.new_client_user,
    getClientUsers: state => state.client_users,
    getLoadingUser: state => state.loadingUser,
    getShowModalNewUser: state => state.show_modal_new_user,
}

export default {
    state,
    getters,
    mutations,
    actions
};