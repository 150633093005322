// import Vue from 'vue'
import Vuex from 'vuex'
import helpers from './modules/helpers';
import user from './modules/user';
import product from './modules/product';
import category from './modules/category';
import tag from './modules/tag';
import attribute from './modules/attribute';
import characteristic_variations from './modules/characteristic_variations';
import order from './modules/orders';
import payment from './modules/payments';
import shipping from './modules/shipping';
import product_relation from './modules/product_relation';

export default new Vuex.Store({
    modules: {
        helpers,
        user,
        product,
        category,
        tag,
        attribute,
        characteristic_variations,
        order,
        payment,
        shipping,
        product_relation
    }
})
