import TagService from '../../services/TagService'
import router from '../../router'

const state = () => ({
    loadingTags: false,
    tag: null,
    tags: [],
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_tag: false
})
  
const mutations = {
    SET_TAG: (state, payload) => {
        state.tag =  payload
    },
    SET_TAGS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.tags = payload.itens
        }else{
            state.tags = [...state.tags, ...payload.itens]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total
    },
    SET_LOADING_TAGS: (state, payload) => {
        state.loadingTags = payload
    },
    SET_SHOW_MODAL_NEW_TAG: (state, payload) => {
        state.show_modal_new_tag = payload
    },
}

const actions = {
    async fetchTags({commit}, { keyword = '', pageNumber = 1, sortBy = 'mais recentes' }){
        try{
            commit('SET_LOADING_TAGS', true)
            const response = await TagService.get(keyword, pageNumber, sortBy);
            commit('SET_TAGS', response.data)
            commit('SET_LOADING_TAGS', false)
        } catch (error) {
            commit('SET_LOADING_TAGS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchTag({commit}, id){
        try{
            commit('SET_LOADING_TAGS', true)
            const response = await TagService.getOne(id);
            commit('SET_TAG', response.data)
            commit('SET_LOADING_TAGS', false)
        } catch (error) {
            commit('SET_LOADING_TAGS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createTag({commit, dispatch}, data){
        try{
            commit('SET_LOADING_TAGS', true)
            const response = await TagService.create(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            dispatch('fetchTags', { keyword: ''})
            commit('SET_SHOW_MODAL_NEW_TAG', false)
            commit('SET_LOADING_TAGS', false)
        } catch (error) {
            commit('SET_LOADING_TAGS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadeTag({commit}, data){
        try{
            commit('SET_LOADING_TAGS', true)
            const response = await TagService.update(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_TAGS', false)
        } catch (error) {
            commit('SET_LOADING_TAGS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async deleteTag({state, commit}, id) {
        try{
            commit('SET_LOADING_TAGS', true);
            if(state.tag.id == id){
                const response = await TagService.delete(id);
                router.push({path: `/tags`});
                commit('SET_ALERT', {
                    heading: 'success',
                    message: response.data.message
                });
            }
            commit('SET_SHOW_MODAL_CONFIRMATION', false);
            commit('SET_LOADING_TAGS', false);
        } catch (error) {
            commit('SET_LOADING_TAGS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    }
}

const getters = {
    getTag: state => state.tag,
    getTags: state => state.tags,
    getLoadingTags: state => state.loadingTags,
    getShowModalNewTag: state => state.show_modal_new_tag
}

export default {
    state,
    getters,
    mutations,
    actions
};