import RelationService from '../../services/RelationService'
import router from '../../router'

const state = () => ({
    loadingRelations: false,
    relation: null,
    relations: [],
    newRelation:{
        description: '',
        Products: []
    },
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_relation: false
})
  
const mutations = {
    SET_RELATION: (state, payload) => {
        state.relation =  payload
    },
    SET_RELATIONS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.relations = payload.itens
        }else{
            state.relations = [...state.relations, ...payload.itens]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total
    },
    SET_LOADING_RELATIONS: (state, payload) => {
        state.loadingRelations = payload
    },
    SET_SHOW_MODAL_NEW_RELATION: (state, payload) => {
        state.show_modal_new_relation = payload
    },
}

const actions = {
    async fetchRelations({commit}, { keyword = '', pageNumber = 1, sortBy = 'mais recentes' }){
        try{
            commit('SET_LOADING_RELATIONS', true)
            const response = await RelationService.get(keyword, pageNumber, sortBy);
            commit('SET_RELATIONS', response.data)
            commit('SET_LOADING_RELATIONS', false)
        } catch (error) {
            commit('SET_LOADING_RELATIONS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchRelation({commit}, id){
        try{
            commit('SET_LOADING_RELATIONS', true)
            const response = await RelationService.getOne(id);
            commit('SET_RELATION', response.data)
            commit('SET_LOADING_RELATIONS', false)
        } catch (error) {
            commit('SET_LOADING_RELATIONS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createRelation({commit, dispatch, state}){
        try{
            commit('SET_LOADING_RELATIONS', true)
            const response = await RelationService.create(state.newRelation);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            dispatch('fetchRelations', { keyword: ''})
            commit('SET_SHOW_MODAL_NEW_RELATION', false)
            commit('SET_LOADING_RELATIONS', false)
        } catch (error) {
            commit('SET_LOADING_RELATIONS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadeRelation({commit}, data){
        try{
            commit('SET_LOADING_RELATIONS', true)
            const response = await RelationService.update(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_RELATIONS', false)
        } catch (error) {
            commit('SET_LOADING_RELATIONS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async deleteRelation({state, commit}, id) {
        try{
            commit('SET_LOADING_RELATIONS', true);
            if(state.relation.id == id){
                const response = await RelationService.delete(id);
                router.push({path: `/relations`});
                commit('SET_ALERT', {
                    heading: 'success',
                    message: response.data.message
                });
            }
            commit('SET_SHOW_MODAL_CONFIRMATION', false);
            commit('SET_LOADING_RELATIONS', false);
        } catch (error) {
            commit('SET_LOADING_RELATIONS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    }
}

const getters = {
    getRelation: state => state.relation,
    getRelations: state => state.relations,
    getNewRelation: state => state.newRelation,
    getLoadingRelations: state => state.loadingRelations,
    getShowModalNewRelation: state => state.show_modal_new_relation
}

export default {
    state,
    getters,
    mutations,
    actions
};