import PaymentService from '../../services/PaymentService'
import router from '@/router'

const state = () => ({
    loadingPayment: false,
    payment: null,
    new_payment: {
        payment_method_id: "",
        amount: null,
        installments: 1,
        description: "",
        UserId: null,
        SubscriptionId: null,
        PlanId: null,
        CuponId: null,
        DiscountId: null,
        transaction_id: null,
        external_reference: null,
        customer_id: null,
        identificationNumber: null,
        cardholderEmail: null,
        status: "approved"
    },
    payments: [],
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_payment: false
})
  
const mutations = {
    SET_PAYMENT: (state, payload) => {
        state.payment =  payload
    },
    SET_PAYMENTS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.payments = payload.payments
        }else{
            state.payments = [...state.payments, ...payload.payments]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total
    },
    SET_LOADING_PAYMENTS: (state, payload) => {
        state.loadingPayment = payload
    },
    SET_SHOW_MODAL_NEW_PAYMENT: (state, payload) => {
        state.show_modal_new_payment = payload
    },
}

const actions = {
    async fetchPayments({commit}, { keyword = '', pageNumber = 1, sortBy = 'mais recentes' }){
        try{
            commit('SET_LOADING_PAYMENTS', true)
            const response = await PaymentService.get(keyword, pageNumber, sortBy);
            commit('SET_PAYMENTS', response.data)
            commit('SET_LOADING_PAYMENTS', false)
        } catch (error) {
            commit('SET_LOADING_PAYMENTS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchPayment({commit}, id){
        try{
            commit('SET_LOADING_PAYMENTS', true)
            const response = await PaymentService.getOne(id);
            commit('SET_PAYMENT', response.data)
            commit('SET_LOADING_PAYMENTS', false)
        } catch (error) {
            commit('SET_LOADING_PAYMENTS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createPayment({commit, state}, data){
        try{
            commit('SET_LOADING_PAYMENTS', true)
            const response = await PaymentService.create(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_SHOW_MODAL_NEW_PAYMENT', false)
            state.new_payment = { payment_method_id: "", transaction_amount: null, installments: 1, description: "", UserId: null, SubscriptionId: null, PlanId: null, CuponId: null, DiscountId: null, transaction_id: null, external_reference: null, customer_id: null, identificationNumber: null, cardholderEmail: null, status: "ativo" };
            router.push({path: `/form_payment/${response.data.item.id}`});
            commit('SET_LOADING_PAYMENTS', false)
        } catch (error) {
            commit('SET_LOADING_PAYMENTS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadePayment({commit}, data){
        try{
            commit('SET_LOADING_PAYMENTS', true)
            const response = await PaymentService.update(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_PAYMENTS', false)
        } catch (error) {
            commit('SET_LOADING_PAYMENTS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getPayment: state => state.payment,
    getNewPayment: state => state.new_payment,
    getPayments: state => state.payments,
    getLoadingPayment: state => state.loadingPayments,
    getShowModalNewPayment: state => state.show_modal_new_payment,
}

export default {
    state,
    getters,
    mutations,
    actions
};