import AttributeService from '../../services/AttributeService'
import router from '../../router'

const state = () => ({
    loadingAttributes: false,
    attribute: null,
    attributes: [],
    total: 0,
    pages: 0,
    page: 1,
    ahow_modal_new_Attribute: false
})
  
const mutations = {
    SET_ATTRIBUTE: (state, payload) => {
        state.attribute = payload
    },
    SET_ATTRIBUTES: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.attributes = payload.itens
        }else{
            state.attributes = [...state.attributes, ...payload.itens]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total

    },
    SET_LOADING_ATTRIBUTES: (state, payload) => {
        state.loadingAttribute = payload
    },
    SET_SHOW_MODAL_NEW_ATTRIBUTE: (state, payload) => {
        state.ahow_modal_new_Attribute = payload
    },
}

const actions = {
    async fetchAttribute({commit}, id){
        try{
            commit('SET_LOADING_ATTRIBUTES', true)
            const response = await AttributeService.getOne(id);
            commit('SET_ATTRIBUTE', response.data.item)
            commit('SET_LOADING_ATTRIBUTES', false)
        } catch (error) {
            commit('SET_LOADING_ATTRIBUTES', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchAttributes({commit}, { keyword = '', pageNumber = 1, sortBy = 'mais recentes' }){
        try{
            commit('SET_LOADING_ATTRIBUTES', true)
            const response = await AttributeService.get(keyword, pageNumber, sortBy);
            commit('SET_ATTRIBUTES', response.data)
            commit('SET_LOADING_ATTRIBUTES', false)
        } catch (error) {
            commit('SET_LOADING_ATTRIBUTES', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createAttribute({commit, dispatch}, data){
        try{
            commit('SET_LOADING_ATTRIBUTES', true)
            const response = await AttributeService.create(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            dispatch('fetchAttributes', { keyword: ''})
            commit('SET_SHOW_MODAL_NEW_ATTRIBUTE', false)
            commit('SET_LOADING_ATTRIBUTES', false)
        } catch (error) {
            commit('SET_LOADING_ATTRIBUTES', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadeAttribute({commit}, data){
        try{
            commit('SET_LOADING_ATTRIBUTES', true)
            const response = await AttributeService.update(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_ATTRIBUTES', false)
        } catch (error) {
            commit('SET_LOADING_ATTRIBUTES', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async deleteAttribute({state, commit}, id) {
        try{
            commit('SET_LOADING_ATTRIBUTES', true);
            if(state.attribute.id == id){
                const response = await AttributeService.delete(id);
                router.push({path: `/attributes`});
                commit('SET_ALERT', {
                    heading: 'success',
                    message: response.data.message
                });
            }
            commit('SET_SHOW_MODAL_CONFIRMATION', false);
            commit('SET_LOADING_ATTRIBUTES', false);
        } catch (error) {
            commit('SET_LOADING_ATTRIBUTES', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    }
}

const getters = {
    getAttribute: state => state.attribute,
    getAttributes: state => state.attributes,
    getLoadingAttribute: state => state.loadingAttribute,
    getShowModalNewAttribute: state => state.ahow_modal_new_Attribute
}

export default {
    state,
    getters,
    mutations,
    actions
};