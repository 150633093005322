import Api from './ApiService'

export default {
    get(keyword, pageNumber, sort) {
        return Api().get(`product?keyword=${keyword}&pageNumber=${pageNumber}&sort=${sort}`);
    },
    getOne(id) {
        return Api().get(`product/${id}`);
    },
    create(formData) {
        return Api().post(`product/`, formData);
    },
    update(data) {
        return Api().patch(`product/`, data);
    },
    uploadImages(formData) {
        const config = {
            headers: {
              'Content-Type': 'multipart/form-data', // Indica que estamos enviando FormData
            },
          };
        return Api().patch(`productImage/images`, formData, config);
    },
    delete(idProduct){
        return Api().delete(`product/${idProduct}`);
    }
}