<template>
    <div class="flex justify-between">
        <div>
            <div @click="SET_SHOW_SIDE_NAV(!getshowSideNav)" class="flex items-center justify-between flex-shrink-0 py-2 px-4 cursor-pointer" :class="{'lg:justify-center px-2': !getshowSideNav}">
                <span class="p-2 text-xl text-gray-600 font-semibold leading-8 tracking-wider uppercase whitespace-nowrap">
                    <!-- <img alt="Logo" src="/img/logo.png" class="h-10 object-cover"> -->
                    E<span :class="{'lg:hidden': !getshowSideNav}" class="relative">-COMMERCE
                        <p class="absolute top-4 text-[8px]">DEV Guilherme Schiavon</p>
                    </span>
                </span>
                <button @click="toggleSidbarMenu()" class="p-2 rounded-md lg:hidden">
                    <svg class="w-6 h-6 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </button>
            </div>
            <div class="flex-1 overflow-hidden hover:overflow-y-auto border-t border-gray-100">
                <div class="px-2">
                    <ul class="space-y-1 border-t border-gray-100 pt-4 p-2 text-sm">
                        <li v-for="item in getItensNavBar" :key="item.id">
                            <router-link :to="item.url" :title="item.name" class="flex items-center p-2 space-x-2 rounded-md hover:bg-gray-100" :class="{'justify-center': !getshowSideNav}">
                                <span v-if="item.name == 'Ordens'" class="relative">
                                    <span v-if="getTotalOrdersPendentes" class="absolute flex h-2 w-2 -right-2 -top-1">
                                        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                        <span class="absolute inline-flex rounded-full h-2 w-2 bg-red-500"></span>
                                    </span>
                                    <svg class="h-5 w-5 opacity-50" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" /></svg>
                                </span>
                                <div v-else v-html="item.svg" class="scale-90"></div>
                                <span :class="{ 'lg:hidden': !getshowSideNav }" class="">{{ item.name }}</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="sticky inset-x-0 bottom-0 border-t border-gray-100 bg-white p-2">
            <button @click="LOG_USER_OUT()" type="button" class="group relative flex w-full justify-center rounded-lg border px-2 py-1.5 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 opacity-75" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" /></svg>
                <span :class="{'lg:hidden': !getshowSideNav}" class="ml-3"> Sair </span>
            </button>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapMutations } from 'vuex';

    export default {
        name: "SideNavComponnent",
        components: {},
        async mounted(){
            await this.fetchTotalPendentesPendentes();
        },
        methods: {
            ...mapActions(['fetchTotalPendentesPendentes']),
            ...mapMutations(['SET_SHOW_SIDE_NAV', 'LOG_USER_OUT'])
        },
        computed: {
            ...mapGetters(['getshowSideNav', 'getItensNavBar', 'getTotalOrdersPendentes'])
        }
    }
</script>
<style scoped>
    button {
        background: transparent;
    }
</style>